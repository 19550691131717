import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudysoftnas.scss';

import imgQuote from "../images/quotation_mark.webp";

import SimpleModal from '../components/utilities/SimpleModal';

class FullWidthVideo extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.image+')',
            height: this.props.height
        };
        return (
            <div>
                <div className="container-fluid full-width-video-wrapper" onClick={() => this.openModal()} style={divStyles}>&nbsp;</div>
                <SimpleModal videoSRC={this.props.videoSRC} innerRef={this.modalRef}/>
            </div>
        );
    }
}

class OneImageFull extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundSize: 'cover',
            height: this.props.height,
            backgroundPosition: '50% 50%'
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-two-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width={pageContext.acf.client_logo.width} height={pageContext.acf.client_logo.height} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2024/01/merit-mile-softnas-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div class="container detail-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="first-paragraph">
                            <p>SoftNAS simplifies the challenges businesses face when moving data and applications to the cloud.</p>
                            <p>Whether the cloud environment is Amazon Web Services (AWS), Microsoft Azure, Veeam or VMware, SoftNAS connects applications and data to the cloud, acting as a high-speed data on-ramp.</p>
                            <p>With SoftNAS Cloud®, a cloud-native solution for controlling and managing virtually any type of data, SoftNAS provides the ability to rapidly configure and customize cloud data storage environments, project by project, for optimal price and performance.</p>
                        </div>
                    </div>
                    <div class="col-12"></div>
                </div>
            </div>
            <div class="container single-image-text-wrapper detail-wrapper">
                <div class="row">
                    <div class="col-md-6 order-last">           
                        <img src="https://www.meritmile.com/uploads/2019/05/graphic-desktop@2x-1.jpg" class="img-fluid" width="539" height="450" alt=""              />
                    </div>
                    <div class="col-md-6 text">
                        <div class="text">
                            <h3>Challenge</h3>
                            <p>How do you simplify a complex solution laden with technical specifications, proprietary features, and an alphabet soup of data protocol acronyms?</p>
                            <p>SoftNAS was changing how businesses moved and controlled cloud data but explaining what and how they did it was easier said than done. When SoftNAS first approached Merit Mile, their website and digital collateral was packed full of technical speeds and feeds – perfect for deeply entrenched cloud architects and data scientists – but what about the less cloud-mature customers? SoftNAS needed a top to bottom reset on their messaging, branding, and marketing funnel to reach new customers, align with everyday use cases, and demonstrate the potential costs savings. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container single-image-text-wrapper detail-wrapper">
                <div class="row">
                    <div class="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/05/phone-tablet@2x-1.jpg" class="img-fluid" width="540" height="570" alt="" />
                    </div>
                    <div class="col-md-6 text">
                        <div class="text">
                            <h3>Solutions</h3>
                            <p>Merit Mile began with an extensive series of subject matter expert interviews, which laid the foundation for an official SoftNAS messaging and positioning framework. In parallel, a branding exploration yielded a new corporate brand identity guide, complete with logo usage, color palettes, fonts, artwork styles, and an image library.</p>
                            <p>With creative and messaging tools in hand, Merit Mile designed a new website<br /> intended to:</p>
                            <ul>
                                <li>Quickly articulate the core SoftNAS value proposition in everyday terms</li>
                                <li>Enable visitors to easily self-select their path by professional roles or use cases</li>
                                <li>Increase lead capture opportunities with gated content, trials, and a cost savings calculator </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container detail-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="">
                            <h3>Results</h3>
                            <p>The website launch was complemented with a dramatic and captivating corporate video along with new brochures, datasheets, and presentations. One of the biggest customer benefits of SoftNAS Cloud are the cloud storage cost savings. To make these savings tangible, Merit Mile custom developed the business logic and algorithms behind SoftNAS® SmartTiers™, and then designed an interactive, mobile responsive calculator.</p>
                            <p>Since the launch SoftNAS is now driving more leads and accelerating the sales cycle through the website and calculator. Site traffic is up by over 15%, and marketing qualified leads through the calculator and free trial have increased by 8%.</p>
                        </div>
                    </div>
                </div>
            </div>
            <FullWidthVideo videoSRC={pageContext.childWordPressAcfAddFullWidthVideo.youtube_id} height={pageContext.childWordPressAcfAddFullWidthVideo.background_image.media_details.height/2} image={pageContext.childWordPressAcfAddFullWidthVideo.background_image.source_url} />
            <div class="container single-image-text-wrapper detail-wrapper">
                <div class="row">
                    <div class="col-md-6 order-last">
                        <img src="https://www.meritmile.com/uploads/2019/05/softnas-flow-diagram.svg" class="img-fluid" width="0" height="0" alt="" />
                    </div>
                    <div class="col-md-6 text">
                        <div class="text">
                            <h3>Deliverables</h3>
                            <ul>
                                <li>Brand and style guide</li>
                                <li>Messaging and positioning framework </li>
                                <li>Website redesign and SEO optimization</li>
                                <li>Corporate video</li>
                                <li>Corporate presentations and brochures </li>
                                <li>Web-based cost savings calculator </li>
                                <li>Content templates for PPT, DOC, PDF</li>
                                <li>Digital templates for emails, newsletters, and blogs </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container two-image-wrapper">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <img src="https://www.meritmile.com/uploads/2019/05/softnas-icon-stack.svg" class="img-fluid" alt="" />
                    </div>
                    <div class="col-md-7">
                        <img src="https://www.meritmile.com/uploads/2019/05/sample-tablet@2x-1.jpg" class="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <OneImageFull imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} height={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2}/>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 website-btn text-center">
                        <a href="https://www.softnas.com/" class="btn sitebtn" target="_blank" rel="noopener noreferrer">View Website</a>
                    </div>
                </div>
            </div>
            <div class="container quote-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="text">
                            <p className="text-center">"Complex solutions begin as<br />
                            simple conversations, let's talk."</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
)